import React from "react";
import _ from "lodash";

export default class Contact extends React.Component {
    state = {
        nom: '',
        phone:'',
        prenom:'',
        ste:'',
        email: '',
        object:'',
        message: '',
        botField:null
    };
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        debugger;
        const nom = target.name;
        const ste = target.name;
        const prenom = target.name;
        const phone = target.name;
        const email = target.name;
        const object = target.name;
        const message = target.name;
        const botField=target.name;
        this.setState({
            [nom]: value,
            [ste]: value,
            [prenom]:value,
            [phone]:value,
            [email]:value,
            [object]:value,
            [message]:value,
            [botField]:value
        });
    };
    encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: this.encode({ 'form-name': 'contact', ...this.state })
        }

        fetch(
            "/",
            options
        )
            .then((response)=>{
                window.location.assign('/');
            })
            .catch((error)=>error);
    };
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className="block block-hero has-gradient outer">
                <div className="inner-sm">
                    <div className="block-header">
                        <span className="block-title">On vous écoute !</span>
                        <p>
                            <strong>Une Question, une demande ?</strong><br />
                            <span>Dites-nous ce qui vous faire plaisir, et on y répondra (avec le sourire)!</span>
                        </p>
                    </div>
                    <div id="form_contact" className="block-content">
                        <form name="contact"className="form-submit " onSubmit={this.handleSubmit}
                              id="contact-form"
                              data-netlify-honeypot="bot-field"
                               data-netlify="true">
                            <div className="form-row grid grid-col-2">
                                <div className="grid-item">
                                    <input type="text" name="nom"
                                           value={this.state.nom} onChange={this.handleInputChange}
                                           placeholder="Votre nom*" />
                                </div>
                                <div className="grid-item">
                                    <input type="text" name="phone"
                                           value={this.state.phone} onChange={this.handleInputChange}
                                           placeholder="Votre numéro de téléphone" />

                                </div>
                            </div>
                            <div className="form-row grid grid-col-2">
                                <div className="grid-item">
                                    <input type="text" name="prenom"
                                           value={this.state.prenom} onChange={this.handleInputChange}
                                           placeholder="Votre prénom*" />
                                </div>
                                <div className="grid-item">
                                    <input type="text" name="ste"
                                           value={this.state.ste} onChange={this.handleInputChange}
                                           placeholder="Votre société" />
                                </div>
                            </div>
                            <div className="form-row grid grid-col-2">
                                <div className="grid-item">
                                    <input type="email" name="email"
                                           value={this.state.email} onChange={this.handleInputChange}
                                           placeholder="Votre email*" />
                                </div>
                                <div className="grid-item" style={{'textAlign':'left'}}>
                                    <small className="small-info">Les champs marqués d'un * sont obligatoires</small>
                                </div>
                            </div>
                            <div className="form-row">
                                <input type="text" name="object" placeholder="Objet de votre message*" />
                            </div>
                            <div className="form-row">
                                <textarea name="message"
                                          value={this.state.message} onChange={this.handleInputChange}
                                          placeholder="Votre message*"></textarea>
                            </div>
                            <p className="screen-reader-text">
                                <label>Ne remplis pas ça si tu es humain : <input name="bot-field" /></label>
                            </p>
                            <div className="block-buttons">
                                <button type="submit" className="button button-secondary" >Envoyer</button>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
        )
    }
}
